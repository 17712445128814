<template>
  <section id="suppliers-statistics">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <v-select v-model="selectedSupplier" placeholder="Select supplier" label="title" :options="allSuppliers"
            @input="changeSelectedSupplier" />
        </b-col>
        <b-col xl="6" md="6" />
      </b-row>
    </b-card>
    <b-overlay :show="showSuppliers" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Suppliers statistics
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-suppliers-statistics" />
          </h4>
          <b-popover target="popover-suppliers-statistics" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportStart" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer" @click="exportTable" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="suppliersTable.currentPage"
          :items="suppliersTable.items" :fields="suppliersTable.fields" :sort-by.sync="suppliersTable.sortBy"
          :sort-desc.sync="suppliersTable.sortDesc" :sort-direction="suppliersTable.sortDirection"
          :filter="suppliersTable.filter" :filter-included-fields="suppliersTable.filterOn"
          @sort-changed="suppliersTableSortChanged" @row-clicked="suppliersRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="suppliersTable.currentPage" :total-rows="suppliersTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleSuppliersTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="supplier-details-modal" size="xl" :title="supplierName" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @hidden="resetSupplierDetailsModal"
      @shown="onSupplierDetailsModalShown">
      <b-overlay :show="showProductsBySupplier" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Products by supplier
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                id="popover-products-supplier" />
            </h4>
            <b-popover target="popover-products-supplier" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <b-table striped hover responsive class="position-relative"
            :current-page="productsBySupplierTable.currentPage" :items="productsBySupplierTable.items"
            :fields="productsBySupplierTable.fields" :sort-by.sync="productsBySupplierTable.sortBy"
            :sort-desc.sync="productsBySupplierTable.sortDesc" :sort-direction="productsBySupplierTable.sortDirection"
            :filter="productsBySupplierTable.filter" :filter-included-fields="productsBySupplierTable.filterOn"
            @sort-changed="productsBySupplierTableSortChanged" @row-clicked="productDetailsRowClicked" />
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination v-model="productsBySupplierTable.currentPage"
                :total-rows="productsBySupplierTable.totalRows" first-number last-number prev-class="prev-item"
                next-class="next-item" class="mb-0" @change="handleProductsBySupplierTablePageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-turnover-years" />
              </h4>
              <b-popover target="popover-turnover-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="turnoverByYearChart" :options="turnoverByYearChart" style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. delivery time over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-delivery-time-years" />
              </h4>
              <b-popover target="popover-avg-delivery-time-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgDeliveryTimeByYearChart" :options="avgDeliveryTimeByYearChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Freight Charges over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-freight-charges-years" />
              </h4>
              <b-popover target="popover-freight-charges-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="freightChargesByYearChart" :options="freightChargesByYearChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. Freight Charges over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-freight-charges-years" />
              </h4>
              <b-popover target="popover-avg-freight-charges-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgFreightChargesByYearChart" :options="avgFreightChargesByYearChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Freight Charges Compared To Turnover over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-freight-charges-compared-turnover-years" />
              </h4>
              <b-popover target="popover-freight-charges-compared-turnover-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="freightChargesPercentageByYearChart" :options="freightChargesPercentageByYearChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. order value over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-order-value-years" />
              </h4>
              <b-popover target="popover-avg-order-value-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgOrderValueByYearChart" :options="avgOrderValueByYearChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Brands turnover over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-brands-turnover-years" />
              </h4>
              <b-popover target="popover-brands-turnover-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="brandsTurnoverByYearChart" :options="brandsTurnoverByYearChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. qty sold over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-avg-qty-years" />
              </h4>
              <b-popover target="popover-avg-qty-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgQtyByYearChart" :options="avgQtyByYearChart" style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Products by turnover
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-products-turnover" />
              </h4>
              <b-popover target="popover-products-turnover" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="productsByTurnoverChart" :options="productsByTurnoverChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Categories by turnover
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-categories-turnover" />
              </h4>
              <b-popover target="popover-categories-turnover" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="productsByCategoryChart" :options="productsByCategoryChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="product-details-modal" size="xl" :title="productSKU" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @hidden="resetProductDetailsModal"
      @shown="onProductDetailsModalShown">
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Qty sold over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-qty-sold-years" />
              </h4>
              <b-popover target="popover-qty-sold-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="qtySoldByYearChart" :options="qtySoldByYearChart" style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. price over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-pprice-years" />
              </h4>
              <b-popover target="popover-avg-pprice-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgPriceByYearChart" :options="avgPriceByYearChart" style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
  BCard,
  BCardBody,
  BCardText,
  BCardHeader,
  BPopover,
  BTable,
  BPagination,
  BModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import axios from 'axios';
import * as XLSX from 'xlsx';
import ECharts from 'vue-echarts';
import 'echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BCard,
    BCardBody,
    BCardText,
    BCardHeader,
    BPopover,
    BTable,
    BPagination,
    BModal,
    vSelect,
    ECharts,
  },
  data() {
    return {
      showSuppliers: true,
      showProductsBySupplier: true,
      showSupplierOrderStatsByYear: true,
      showSupplierAvgDeliveryTimeByYear: true,
      showBrandsTurnoverByYear: true,
      showProductsByTurnover: true,
      showProductsByCategory: true,
      showProductDetailsByYear: true,
      showExportStart: false,
      selectedSupplier: '',
      allSuppliers: [],
      supplierID: '',
      supplierName: '',
      productSKU: '',
      queryParamsSuppliersRating: {},
      queryParamsProductsBySupplier: {},
      queryParamsSupplierDetails: {},
      suppliersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'rank', label: 'rank', sortable: true },
          {
            key: 'rank_last_year',
            label: 'rank last year',
            sortable: true,
            formatter: (value, key, item) => {
              if (value > item.rank) {
                return `${value} ↓`;
              }
              if (value < item.rank) {
                return `${value} ↑`;
              }
              return `${value} =`;
            },
          },
          { key: 'supplier', label: 'supplier', sortable: true },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_last_year',
            label: 'turnover last year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'avg_extra_cost',
            label: 'avg extra cost',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'avg_delivery_time',
            label: 'avg. delivery time',
            sortable: true,
            formatter: val => `${val} days`,
          },
          {
            key: 'reliability',
            label: 'reliability',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      productsBySupplierTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty', label: 'qty', sortable: true },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'brand', label: 'brand', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      turnoverByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value} €<br/>`;
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgDeliveryTimeByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value}<br/>`;
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgOrderValueByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value} €<br/>`;
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgQtyByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value}<br/>`;
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      productsByTurnoverChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      productsByCategoryChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      brandsTurnoverByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value} €<br/>`;
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      qtySoldByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value}<br/>`;
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgPriceByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value} €<br/>`;
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      freightChargesByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value} €<br/>`;
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgFreightChargesByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value} €<br/>`;
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      freightChargesPercentageByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value} %<br/>`;
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    try {
      await this.getAllSuppliers();
      await this.getSuppliersRating();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllSuppliers() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-suppliers/`, {});
        this.allSuppliers = response.data.results.map(item => item.value);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getSuppliersRating() {
      this.showSuppliers = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/suppliers-rating/`, this.queryParamsSuppliersRating);
        this.suppliersTable.items = response.data.results;
        this.suppliersTable.totalRows = response.data.results[0].count * 2;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showSuppliers = false;
      }
    },
    async getProductsBySupplier() {
      this.showProductsBySupplier = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-by-supplier/`, this.queryParamsProductsBySupplier);
        this.productsBySupplierTable.items = response.data.results;
        this.productsBySupplierTable.totalRows = response.data.results[0].count * 2;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductsBySupplier = false;
      }
    },
    async getSupplierOrderStatsByYear() {
      this.showSupplierOrderStatsByYear = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/supplier-order-stats-by-year/`, this.queryParamsSupplierDetails);
        const results = response.data.results;
        this.turnoverByYearChart.xAxis.data = results.map(item => item.year);
        this.turnoverByYearChart.series[0] = {
          name: 'Turnover YTD',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? `${params.value.toLocaleString('de-DE')} YTD` : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover_ytd),
        };
        this.turnoverByYearChart.series[1] = {
          name: 'Turnover',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover),
        };
        this.avgOrderValueByYearChart.xAxis.data = results.map(item => item.year);
        this.avgOrderValueByYearChart.series = {
          name: 'Avg. order value',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.avg_order_value),
        };
        this.avgQtyByYearChart.xAxis.data = results.map(item => item.year);
        this.avgQtyByYearChart.series = {
          name: 'Avg. qty sold',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.avg_qty),
        };
        this.freightChargesByYearChart.xAxis.data = results.map(item => item.year);
        this.freightChargesByYearChart.series = {
          name: 'Freight charges',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.sum_freight_charges),
        };
        this.avgFreightChargesByYearChart.xAxis.data = results.map(item => item.year);
        this.avgFreightChargesByYearChart.series = {
          name: 'Avg. freight charges',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.avg_freight_charges),
        };
        this.freightChargesPercentageByYearChart.xAxis.data = results.map(item => item.year);
        this.freightChargesPercentageByYearChart.series = {
          name: 'Freight charges percentage',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.freight_charge_percentage),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showSupplierOrderStatsByYear = false;
      }
    },
    async getProductsByTurnover() {
      this.showProductsByTurnover = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/supplier-products-by-turnover/`, this.queryParamsSupplierDetails);
        this.productsByTurnoverChart.series[0].data = response.data.results.map(item => {
          return {
            value: item.turnover,
            name: item.sku,
          };
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductsByTurnover = false;
      }
    },
    async getProductsByCategory() {
      this.showProductsByCategory = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/supplier-products-by-category/`, this.queryParamsSupplierDetails);
        this.productsByCategoryChart.series[0].data = response.data.results.map(item => {
          return {
            value: item.turnover,
            name: item.category,
          };
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductsByCategory = false;
      }
    },
    async getSupplierAvgDeliveryTimeByYear() {
      this.showSupplierAvgDeliveryTimeByYear = true;
      const params = {
        supplier_id: this.supplierID,
        from_date: `${new Date().getFullYear() - 2}` + '-1-1',
        to_date: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
      };

      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/supplier-avg-delivery-time-by-year/`, params);
        const results = response.data.results;
        this.avgDeliveryTimeByYearChart.xAxis.data = results.map(item => item.year);
        this.avgDeliveryTimeByYearChart.series = {
          name: 'Avg. delivery time',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.avg_delivery_time),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showSupplierAvgDeliveryTimeByYear = false;
      }
    },
    async getSupplierBrandsTurnoverByYear() {
      this.showBrandsTurnoverByYear = true;
      const params = {
        supplier_id: this.supplierID,
        from_date: `${new Date().getFullYear() - 3}` + '-1-1',
        to_date: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
      };

      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/supplier-brands-turnover-by-year/`, params);
        const results = response.data.results;
        const turnoverYearLabels = [...new Set(results.map(item => item.year))];
        const groupedByBrand = results.reduce((acc, curr) => {
          const key = curr.brand
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(curr)
          return acc
        }, {});

        let brandsTurnoverByYearDatasets = [];
        let c = 0;
        for (const key in groupedByBrand) {
          ;
          let yearValues = groupedByBrand[key].map(item => item.year);
          let turnoverValues = groupedByBrand[key].map(item => item.turnover);
          let turnoverData = [];
          for (var i = 0; i < turnoverYearLabels.length; i++) {
            let foundValue = false;
            for (var j = 0; j < yearValues.length; j++) {
              if (turnoverYearLabels[i] == yearValues[j]) {
                turnoverData.push(turnoverValues[j]);
                foundValue = true;
                break;
              }
            }
            if (!foundValue) {
              turnoverData.push(0.0);
            }
          }
          brandsTurnoverByYearDatasets.push({
            name: key,
            type: 'line',
            areaStyle: {},
            data: turnoverData,
          });
          c += 1;
        }

        this.brandsTurnoverByYearChart.xAxis.data = turnoverYearLabels;
        this.brandsTurnoverByYearChart.series = brandsTurnoverByYearDatasets;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showBrandsTurnoverByYear = false;
      }
    },
    async getProductDetails() {
      this.showProductDetailsByYear = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/product-details-by-year/`, { sku: this.productSKU });
        const results = response.data.results;
        this.qtySoldByYearChart.xAxis.data = results.map(item => item.year);
        this.qtySoldByYearChart.series = {
          name: 'Items sold',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.qty_sold),
        };
        this.avgPriceByYearChart.xAxis.data = results.map(item => item.year);
        this.avgPriceByYearChart.series = {
          name: 'Avg. price',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.avg_price),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductDetailsByYear = false;
      }
    },
    suppliersRowClicked(row) {
      this.supplierID = row.supplier_id;
      this.supplierName = row.supplier;
      this.queryParamsProductsBySupplier.supplier_id = this.supplierID;
      this.queryParamsSupplierDetails.supplier_id = this.supplierID;
      this.getProductsBySupplier();
      this.getSupplierOrderStatsByYear();
      this.getProductsByTurnover();
      this.getProductsByCategory();
      this.getSupplierAvgDeliveryTimeByYear();
      this.getSupplierBrandsTurnoverByYear();
      this.$refs['supplier-details-modal'].show();
    },
    async changeSelectedSupplier() {
      this.queryParamsSuppliersRating.supplier = this.selectedSupplier;
      await this.getSuppliersRating();
    },
    async suppliersTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsSuppliersRating.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsSuppliersRating.ordering = value.sortBy;
      }
      await this.getSuppliersRating();
    },
    async productsBySupplierTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsProductsBySupplier.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsProductsBySupplier.ordering = value.sortBy;
      }
      await this.getProductsBySupplier();
    },
    async handleSuppliersTablePageChange(value) {
      this.queryParamsSuppliersRating.page = value;
      await this.getSuppliersRating();
    },
    async handleProductsBySupplierTablePageChange(value) {
      this.queryParamsProductsBySupplier.page = value;
      await this.getProductsBySupplier();
    },
    productDetailsRowClicked(row) {
      this.productSKU = row.sku;
      this.getProductDetails();
      this.$refs['product-details-modal'].show();
    },
    resetSupplierDetailsModal() {
      this.supplierID = '';
      this.supplierName = '';
    },
    resetProductDetailsModal() {
      this.productSKU = '';
    },
    onSupplierDetailsModalShown() {
      this.$nextTick(() => {
        this.$refs.turnoverByYearChart.resize();
        this.$refs.avgDeliveryTimeByYearChart.resize();
        this.$refs.freightChargesByYearChart.resize();
        this.$refs.avgFreightChargesByYearChart.resize();
        this.$refs.freightChargesPercentageByYearChart.resize();
        this.$refs.avgOrderValueByYearChart.resize();
        this.$refs.brandsTurnoverByYearChart.resize();
        this.$refs.avgQtyByYearChart.resize();
        this.$refs.productsByTurnoverChart.resize();
        this.$refs.productsByCategoryChart.resize();
      });
    },
    onProductDetailsModalShown() {
      this.$nextTick(() => {
        this.$refs.qtySoldByYearChart.resize();
        this.$refs.avgPriceByYearChart.resize();
      });
    },
    async exportTable() {
      try {
        this.showExportStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-suppliers-rating/`, this.queryParamsSuppliersRating);
        const exportedData = response.data.results;
        this.showExportStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'SuppliersStatistics.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
